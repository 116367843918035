import { Link } from "react-router-dom"
import OptionChainTable from '../optionchain/FutureDashbaord/OptionChainTable'

export default function FutureOptionData({globaldata}) {
  return (
    <div className="news-original  ">
    <div className="row">
      <div className="col-sm-6 col-lg-4">
        {globaldata.conresults &&
          globaldata.conresults.length > 0 ? (
          <OptionChainTable
            results={globaldata.conresults}
            heading="Active By Contracts"
            columnName="Contracts"
            columnKey="contracts"
          />
        ) : (
          ""
        )}
      </div>
      <div className="col-sm-6 col-lg-4">
        {globaldata.oiresults && globaldata.oiresults.length > 0 ? (
          <OptionChainTable
            results={globaldata.oiresults}
            heading="Active By OI"
            columnName="OI"
            columnKey="open_int"
          />
        ) : (
          ""
        )}
      </div>

      <div className="col-sm-6 col-lg-4">
        {globaldata.oicresults &&
          globaldata.oicresults.length > 0 ? (
          <OptionChainTable
            results={globaldata.oicresults}
            heading="Active By OI Gainer"
            columnName="OI Gainer"
            columnKey="chg_in_ol"
          />
        ) : (
          ""
        )}
      </div>

      <div className="col-sm-6 col-lg-4">
        {globaldata.traderesults &&
          globaldata.traderesults.length > 0 ? (
          <OptionChainTable
            results={globaldata.traderesults}
            heading="Active By Traded Value(Lakh.)"
            columnName="Traded Value"
            columnKey="val_inlakh"
          />
        ) : (
          ""
        )}
      </div>

      <div className="col-sm-6 col-lg-4">
        {globaldata.pricecresults &&
          globaldata.pricecresults.length > 0 ? (
          <OptionChainTable
            results={globaldata.pricecresults}
            heading="Active By Price(%CHG) Gainer"
            columnName="Price Chg"
            columnKey="cchg"
          />
        ) : (
          ""
        )}
      </div>

      <div className="col-sm-6 col-lg-4">
        {globaldata.oiGainer && globaldata.oiGainer.length > 0 ? (
          <OptionChainTable
            results={globaldata.oiGainer}
            heading="Active By OI% Gainer"
            columnName="OI% Gainer"
            columnKey="oiper"
          />
        ) : (
          ""
        )}
      </div>   
       

      

       
      <div className="col-md-12 text-center">
                    <Link
                      className=" home-btn-outline"
                      to="/futures-and-options/"
                    >
                      View All
                    </Link>
                  </div>
    </div>
  </div>
  )
}
